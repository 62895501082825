<template>
    <div class="MeterTypeDetail">
        <div class="row-vh d-flex flex-row">
            <div class="w-50">
                <div class="d-flex flex-row align-items-center mt-3">
                    <div class="col-4 text-left d-flex">
                        <span class="h5 mb-0">{{ $t("계측기 유형 코드") }}</span
                        ><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
                    </div>
                    <div class="col-8">
                        <!-- <input :ref="'meterTypeCode'" class="form-control" v-model="model.meterTypeCode" disabled="disabled" /> -->
                        <valid-input
                            :vid="'계측기 유형 코드'"
                            :ref="'meterTypeCode'"
                            :inputType="'text'"
                            :classList="'form-control'"
                            :readonly="true"
                            :inputValue.sync="model.meterTypeCode"
                            :rules="rules.EQUIP_TYPE_CODE_RULE"
                            :errorMessage="validMessage.EQUIP_TYPE_CODE_VALID_MESSAGE"
                        ></valid-input>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="d-flex flex-row align-items-center">
                        <div class="col-4 text-left d-flex">
                            <span class="h5 mb-0">{{ $t("설비 아이콘") }}</span>
                        </div>
                        <div class="col-8">
                            <!-- <input :ref="'iconFilePath'" class="form-control" v-model="model.iconFilePath" /> -->
                            <div :style="{ display: 'flex' }">
                                <input
                                    :ref="'iconFilePath'"
                                    class="form-control col-8"
                                    v-model="model.iconFilePath"
                                    disabled
                                />
                                <b-button
                                    class="col-4"
                                    :style="{ marginLeft: '3px', marginRight: '3px', fontSize: '10px' }"
                                    @click="iconFileImport"
                                    >{{ $t("파일 찾기") }}</b-button
                                >
                            </div>

                            <b-form-file
                                :ref="'iconFileImport'"
                                class="form-control"
                                v-model="tmpIconFile"
                                :state="Boolean(tmpIconFile)"
                                accept="image/*"
                                placeholder=""
                                drop-placeholder=""
                                @change="onIconFileChange"
                                :style="{ display: 'none' }"
                            ></b-form-file>
                        </div>
                    </div>
                    <div :style="{ textAlign: 'center' }" v-if="tmpIconFile === null">
                        <a @click="$bvModal.show('previewIcon')" :style="{ color: 'blue' }">{{
                            $t("파일 미리 보기")
                        }}</a>
                    </div>

                    <b-modal id="previewIcon" size="sm" title="Icon Preview" hide-footer centered>
                        <div :style="{ textAlign: 'center' }">
                            <b-img v-if="tmpIconFile !== null" :src="tmpIconPath" :style="{ width: '100%' }"></b-img>
                            <b-img
                                v-else-if="model.iconFilePath !== ''"
                                :src="`${xemsHost + model.iconFilePath}`"
                                :style="{ width: '100%' }"
                            ></b-img>
                            <p v-else>{{ $t("저장된 이미지가 없습니다.") }}</p>
                        </div>
                    </b-modal>
                    <b-modal id="noneIcon" size="sm" title="Icon Preview" hide-footer centered> </b-modal>
                </div>
                <!-- <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left d-flex">
            <span class="h5 mb-0">계측기 아이콘</span>
          </div>
          <div class="col-8">
            <input :ref="'iconFilePath'" class="form-control" v-model="model.iconFilePath" />
          </div>
        </div> -->
            </div>
            <div class="w-50">
                <div class="d-flex flex-row align-items-center mt-3">
                    <div class="col-4 text-left d-flex">
                        <span class="h5 mb-0">{{ $t("계측기 유형명") }}</span
                        ><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
                    </div>
                    <div class="col-8">
                        <valid-input
                            :vid="'계측기 유형명'"
                            :ref="'meterTypeCodeName'"
                            :inputType="'text'"
                            :classList="'form-control'"
                            :inputValue.sync="model.meterTypeCodeName"
                            :rules="rules.EQUIP_TYPE_CODE_NAME_RULE"
                            :errorMessage="validMessage.EQUIP_TYPE_CODE_NAME_VALID_MESSAGE"
                        ></valid-input>
                    </div>
                </div>
                <div class="d-flex flex-row align-items-center mt-3">
                    <div class="col-4 text-left">
                        <span class="h5">{{ $t("사용여부") }}</span
                        ><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
                    </div>
                    <div class="col-8">
                        <select class="form-control" :ref="'enabled'" v-model="model.enabled">
                            <option value="">{{ $t("선택") }}</option>
                            <option value="Y">{{ $t("사용") }}</option>
                            <option value="N">{{ $t("사용안함") }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-vh d-flex flex-row">
            <div class="w-100">
                <div class="d-flex flex-row align-items-center mt-3">
                    <div class="col-2 text-left d-flex">
                        <span class="h5 mb-0">{{ $t("계측기 유형 설명") }}</span>
                    </div>
                    <div class="col-10">
                        <!-- <textarea :ref="'meterTypemDetailDsc'" class="form-control" v-model="model.meterTypemDetailDsc" /> -->
                        <valid-textarea
                            :ref="'meterTypemDetailDsc'"
                            :classList="'form-control'"
                            :inputType="'text'"
                            :inputValue.sync="model.meterTypemDetailDsc"
                            :rules="rules.EQUIP_TYPE_CODE_DETAIL_RULE"
                            :NonStatus="true"
                        ></valid-textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.MeterTypeDetail {
    .flex-row {
        .text-left {
            padding: 0;
        }
    }
}

.form-control-none {
    display: block;
    width: 100%;
    height: calc(1.5em + 14px + 2px);
    padding: 7px 0.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #2d353c;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #d5dbe0;
    border-radius: 4px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>

<script>
import backEndApi from "../../../api/backEndApi";
import config from "@libs/config.js";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
    components: {},
    props: ["meterTypeCode"],
    watch: {},
    data() {
        return {
            model: {
                meterTypeCode: "",
                meterTypeCodeName: "",
                meterTypemDetailDsc: "",
                iconFilePath: "",
                enabled: "",
            },
            originModel: {
                meterTypeCode: "",
                meterTypeCodeName: "",
                meterTypemDetailDsc: "",
                iconFilePath: "",
                enabled: "",
            },
            tmpIconFile: null,
            tmpIconPath: "",
            xemsHost: config.backend.apiUrl,
            rules,
            validMessage,
        };
    },
    computed: {},
    async created() {
        await this.setModel();
    },
    methods: {
        /**
         * NOTE - 설비 아이콘 이미지 Import
         * @param {Event} e
         */
        onIconFileChange(e) {
            if (e.target.files.length === 0) {
                this.tmpIconFile = null;
                this.model.iconFilePath = this.originModel.iconFilePath;
            } else {
                this.tmpIconFile = e.target.files[0];
                this.model.iconFilePath = e.target.files[0].name;
            }

            // this.tmpIconPath = new Blob([this.tmpIconFile], { type: "image/png" });
            // this.tmpIconPath = URL.createObjectURL(this.tmpIconPath);
            // window.URL.revokeObjectURL(this.tmpIconPath);
        },

        iconFileImport() {
            // console.log(this.$refs);
            this.$refs.iconFileImport.$refs.input.click();
        },

        async setModel() {
            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
        ↓↓↓↓↓
      */
            try {
                const result = await backEndApi.meterType.getMeterType(this.meterTypeCode);
                const data = result.data;

                this.model = {
                    meterTypeCode: data.meterTypeCode,
                    meterTypeCodeName: data.meterTypeCodeName,
                    meterTypemDetailDsc: data.meterTypemDetailDsc,
                    iconFilePath: data.iconFilePath,
                    enabled: data.enabled,
                };

                this.originModel = {
                    meterTypeCode: data.meterTypeCode,
                    meterTypeCodeName: data.meterTypeCodeName,
                    meterTypemDetailDsc: data.meterTypemDetailDsc,
                    iconFilePath: data.iconFilePath,
                    enabled: data.enabled,
                };
            } catch (err) {
                new AppErrorHandler(err).printErrorLog().errHandling();
            }
        },
        async onModify() {
            if (this.isValidation()) {
                let param = {
                    meterTypeCode: this.$refs.meterTypeCode.value,
                    meterTypeCodeName: this.$refs.meterTypeCodeName.value,
                    meterTypemDetailDsc: this.$refs.meterTypemDetailDsc.value,
                    iconFilePath: this.tmpIconFile !== null ? this.tmpIconFile : this.$refs.iconFilePath.value,
                    enabled: this.$refs.enabled.value,
                };

                // console.log(param);

                // 이전 리비전과 소스 에서 아래와 같이 변경함.
                /*           
				↓↓↓↓↓
			*/
                try {
                    await backEndApi.meterType.updateMeterType(param);
                    this.tmpIconPath = "";
                    this.tmpIconFile = null;
                    this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
                    this.$emit("reSearch");
                } catch (err) {}
            }
        },
        isValidation() {
            var flag = true;

            // if (!this.$refs.meterTypeCode.value) {
            //   this.$refs.meterTypeCode.focus();
            //   this.alertNoti(popupMessages.CODE_METER_REGIST_CODE_VALID_POPUP_MESSAGE);
            //   flag = false;
            // } else if (!this.$refs.meterTypeCodeName.value) {
            //   this.$refs.meterTypeCodeName.focus();
            //   this.alertNoti(popupMessages.CODE_METER_REGIST_NAME_VALID_POPUP_MESSAGE);
            //   flag = false;
            // } else if (!this.$refs.enabled.value) {
            //   this.$refs.enabled.focus();
            //   this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
            //   flag = false;
            // }
            if (!this.$refs.enabled.value) {
                this.$refs.enabled.focus();
                this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
                flag = false;
            }

            return flag;
        },
        async onRemove() {
            // console.log("Grid detail : onRemove");
            // console.log(this.model.meterTypeCode);

            // let param = {
            //   meterTypeCode : this.$refs.meterTypeCode.value,
            // }

            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
        ↓↓↓↓↓
      */
            try {
                const popupResult = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!popupResult.value) return;

                await backEndApi.meterType.deleteMeterType(this.model.meterTypeCode);

                this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                this.$emit("reSearch");
            } catch (err) {
                new AppErrorHandler(err)
                    .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
                    .errHandling();
            }
        },
        onReset() {
            this.model = {
                meterTypeCode: this.originModel.meterTypeCode,
                meterTypeCodeName: this.originModel.meterTypeCodeName,
                meterTypemDetailDsc: this.originModel.meterTypemDetailDsc,
                iconFilePath: this.originModel.iconFilePath,
                enabled: this.originModel.enabled,
            };
        },
    },
};
</script>
